import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

const CreditCard: React.FC<IconBasePropsWithColor> = ({ width = 32, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.844 23.9779C1.40488 24.0036 1.02787 23.6609 1 23.2106V1.76861C1.02787 1.31836 1.40488 0.975637 1.844 1.00136H32.156C32.5951 0.975637 32.9721 1.31836 33 1.76861V23.1039C32.9699 23.6284 32.5313 24.0287 32.02 23.9984L1.844 23.9779Z"
        fill="#FAFBFF"
        stroke="#008481"
        strokeWidth="1.55259"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.15">
        <path d="M31 3V22H12L31 3Z" fill="#00C1BF" />
      </g>
      <rect x="5" y="5" width="24" height="4" fill="#53B700" />
      <path
        d="M11 16.0393C11 17.1222 10.0485 18 8.87466 18L7.22117 17.9268C6.43924 17.958 5.70182 17.5909 5.30078 16.9709C4.89974 16.3508 4.89974 15.5778 5.30078 14.9578C5.70182 14.3378 6.43924 13.9707 7.22117 14.0018"
        stroke="#00C1BF"
        strokeWidth="0.790123"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15.9652C8.99893 15.4444 9.22586 14.9445 9.63076 14.5758C10.0356 14.2072 10.5853 14 11.1584 14L12.8416 14.0769C14.0337 14.0769 15 14.9551 15 16.0384C15 17.1218 14.0337 18 12.8416 18"
        stroke="#00C1BF"
        strokeWidth="0.790123"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreditCard;
